@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }

    .active{
    }
  }

@layer components {
  .btn {
    @apply px-[25.5px] py-[14px] bg-[#2E38BB]
      text-white text-sm rounded-xl font-semibold cursor-pointer inline-block;
  }
  .link{
    @apply text-sm font-semibold text-Secondary-Dark-Blue-db-500 underline;
  }
  .cont{
    @apply px-5 md:px-10 lg:px-20 xl:px-[120px] max-w-[1440px] mx-auto;
  }
  .hero-section::before,
  .hero-section::after {
    @apply absolute  bg-[#9DA3D5] h-[255px] w-[216px] opacity-20 blur-[30px] content-[''];
  }
  .hero-section::before {
    @apply right-[-71px] top-[-82px];
  }
  .hero-section::after {
    @apply bottom-[-127px] left-[-129px];
  }
  .feature-component{
    transform-style: preserve-3d;
  }
  .feature-component::before{
    @apply absolute -left-1 top-0  h-full w-full rounded-xl  content-[''];
    transform: translateZ(-1px);
  }
}

body{
  scroll-behavior: smooth;
}

*{
  font-family: 'Noto Sans', "Tajawal", sans-serif;
}


input#Field-numberInput{
  padding-inline-end: 16px!important
}